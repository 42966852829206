import { Customer } from '@msdyn365-commerce/retail-proxy';
import { ICoreContext } from '@msdyn365-commerce/core';
import { updateAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CustomersDataActions.g';

export const subscribeCustomer = async (customer: Customer, attributeName: string, context: ICoreContext) => {
    if (!customer.ExtensionProperties) {
        customer.ExtensionProperties = [];
    }
    let attribute = customer.ExtensionProperties.find(item => item.Key === attributeName);
    if (!attribute) {
        attribute = {
            Key: attributeName,
            Value: { IntegerValue: 1 }
        };
        customer.ExtensionProperties.push(attribute);
    } else {
        attribute.Value = { IntegerValue: 1 };
    }
    return updateAsync({ callerContext: context.actionContext }, customer);
};
